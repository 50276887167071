<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Map View">

                <b-row>
                    <b-col>
                        <b-form-group>
                            <b-form-checkbox v-model="displayConfig.areas.display">Show areas</b-form-checkbox>
                        </b-form-group>
                        <div v-if="displayConfig.areas.display">
                            <b-form-group>
                                <label>Display type</label>
                                <v-select
                                    v-model="displayConfig.areas.type"
                                    :reduce="type => type.id"
                                    label="name"
                                    :options="areaDisplayTypes"
                                />
                            </b-form-group>
                            <b-form-group>
                                <b-form-checkbox v-model="displayConfig.areas.outlinesEnabled">Outlines enabled</b-form-checkbox>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <b-form-checkbox v-model="displayConfig.outlets.display">Show outlets</b-form-checkbox>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox v-model="clusterOutlets">Cluster outlets</b-form-checkbox>
                        </b-form-group>
                        <div v-if="displayConfig.outlets.display">
                            <b-form-group>
                                <label>Color by</label>
                                <v-select
                                    v-model="displayConfig.outlets.color"
                                    :reduce="type => type.id"
                                    label="name"
                                    :options="outletDisplayColors"
                                />
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>

                <hr/>
                <div id="map" style="width: 100%; height: 500px"></div>
                <hr/>
                <b-row>
                    <b-col v-if="displayConfig.areas.display">
                        <h4 class="mb-2">Area legend</h4>
                        <table>
                            <tr v-for="(item, index) in areaLegendItems" :key="index">
                                <th class="pb-50"><span class="font-weight-bold mr-2">{{ item.name }}</span></th>
                                <td class="pb-50">
                                    <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: item.color }"></div>
                                </td>
                            </tr>
                        </table>
                    </b-col>
                    <b-col v-if="displayConfig.outlets.display">
                        <h4 class="mb-2">Outlets legend</h4>
                        <table>
                            <tr v-for="(item, index) in outletLegendItems" :key="index">
                                <th class="pb-50"><span class="font-weight-bold mr-2">{{ item.name }}</span></th>
                                <td class="pb-50">
                                    <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: item.color }"></div>
                                </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BOverlay, BFormGroup, BFormCheckbox, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        components: {
            vSelect,
            BCard,
            BOverlay,
            BFormGroup,
            BFormCheckbox,
            BRow,
            BCol
        },
        data() {
            return {
                dataLoaded: false,
                outlets: [],
                outlet_segments: [],
                outlet_segments_map: new Map(),
                areas: [],
                areas_map: new Map(),
                territories: [],
                territories_map: new Map(),
                districts: [],
                districts_map: new Map(),

                areaDisplayTypes: [
                    { id: 0, name: 'Areas' },
                    { id: 1,  name: 'Territories' },
                    { id: 2, name: 'Districts' }
                ],

                outletDisplayColors: [
                    { id: 0, name: 'Default' },
                    { id: 1, name: 'Status' },
                    { id: 2, name: 'Area is defined' },
                    { id: 3, name: 'Segment' },
                    { id: 4, name: 'Area' },
                    { id: 5, name: 'Territory' },
                    { id: 6, name: 'District' },
                    { id: 7, name: 'Has promotional item' }
                ],

                displayConfig: {
                    outlets: {
                        display: true,
                        color: 0,
                        selected_outlets: [],
                        selected_ids: []
                    },
                    areas: {
                        display: false,
                        outlinesEnabled: true,
                        type: 0,
                        selected_ids: []
                    }
                },

                displayedMapData: {
                    outlets: [],
                    areas: [],
                    outlinesEnabled: true
                },

                mapData: null,

                map: null,
                clusterOutlets: true

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const outletLoadPromise = this.$http.get('/api/management/v1/map_data/outlets')
                outletLoadPromise.then(function(response) {
                    thisIns.outlets = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const segmentLoadPromise = this.$http.get('/api/management/v1/outlet_segment')
                segmentLoadPromise.then(function(response) {
                    thisIns.outlet_segments = response.data
                    thisIns.outlet_segments_map = new Map(thisIns.outlet_segments.map(segment => [segment.id, segment]))
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const areaLoadPromise = this.$http.get('/api/management/v1/area')
                areaLoadPromise.then(function(response) {
                    thisIns.areas = response.data
                    thisIns.areas_map = new Map(thisIns.areas.map(area => [area.id, area]))
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const territoryLoadPromise = this.$http.get('/api/management/v1/territory')
                territoryLoadPromise.then(function(response) {
                    thisIns.territories = response.data
                    thisIns.territories_map = new Map(thisIns.territories.map(territory => [territory.id, territory]))
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const districtLoadPromise = this.$http.get('/api/management/v1/district')
                districtLoadPromise.then(function(response) {
                    thisIns.districts = response.data
                    thisIns.districts_map = new Map(thisIns.districts.map(district => [district.id, district]))
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([outletLoadPromise, segmentLoadPromise, areaLoadPromise, territoryLoadPromise, districtLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                    thisIns.loadMapData()
                })
            },
            loadMapData() {

                this.mapData = {
                    outlets: this.getOutletsMapDisplayData(),
                    areas: this.getAreasMapDisplayData()
                }

                this.removeDisplayedMapData()

                const thisIns = this

                this.displayedMapData.outlinesEnabled = this.displayConfig.areas.outlinesEnabled
                this.setOutletsSource(this.mapData.outlets)
                this.mapData.areas.forEach(area => thisIns.addAreaLayerToMap(area))
            },
            addOutletsSource() {
                this.map.addSource('outlets', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    },
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                })

                this.map.addSource('outlets-unclustered', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                })
            },
            setOutletsSource(outlets) {

                const outletsGeoJson = {
                    type: 'FeatureCollection',
                    features: outlets.map(outlet => {
                        return {
                            type: 'Feature',
                            properties: {
                                popup_data: outlet.popup_data,
                                color: outlet.color
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: outlet.geo_location
                            }
                        }
                    })
                }

                this.map.getSource('outlets').setData(outletsGeoJson)
                this.map.getSource('outlets-unclustered').setData(outletsGeoJson)
            },
            addOutletsDisplayLayers() {


                this.map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'outlets',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#2ecc71',
                            100,
                            '#f1c40f',
                            300,
                            '#f39c12',
                            500,
                            '#e67e22',
                            750,
                            '#d35400',
                            1500,
                            '#e74c3c',
                            3000,
                            '#c0392b'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            25,
                            300,
                            30,
                            500,
                            35,
                            750,
                            40,
                            1500,
                            45,
                            3000,
                            50
                        ]
                    }
                })

                this.map.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'outlets',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-size': 12
                    }
                })

                this.map.on('click', 'clusters', (e) => {
                    const features = this.map.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    })
                    const clusterId = features[0].properties.cluster_id
                    this.map.getSource('outlets').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return

                            this.map.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom
                            })
                        }
                    )
                })

                this.map.addLayer({
                    id: 'outlet-point',
                    type: 'circle',
                    source: 'outlets',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': ['get', 'color'],
                        'circle-radius': 8,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                })

                this.map.addLayer({
                    id: 'outlet-point-unclustered',
                    type: 'circle',
                    source: 'outlets-unclustered',
                    layout: {
                        visibility: 'none'
                    },
                    paint: {
                        'circle-color': ['get', 'color'],
                        'circle-radius': 8,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                })

                this.map.on('click', 'outlet-point', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice()

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
                    }

                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(
                            `${e.features[0].properties.popup_data}`
                        )
                        .addTo(this.map)
                })

                this.map.on('click', 'outlet-point-unclustered', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice()

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
                    }

                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(
                            `${e.features[0].properties.popup_data}`
                        )
                        .addTo(this.map)
                })

                this.map.on('mouseenter', 'clusters', () => {
                    this.map.getCanvas().style.cursor = 'pointer'
                })
                this.map.on('mouseleave', 'clusters', () => {
                    this.map.getCanvas().style.cursor = ''
                })
                
            },
            addAreaLayerToMap(area) {

                this.map.addSource(area.id, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [area.polygon]
                        }
                    }
                })

                this.map.addLayer({
                    'id': `${area.id  }_fill`,
                    'type': 'fill',
                    'source': area.id,
                    'layout': {},
                    'paint': {
                        'fill-color': (area.color.length > 0) ? area.color : '#000',
                        'fill-opacity': (area.color.length > 0) ? 0.5 : 0
                    }
                }, 'clusters')

                if (this.displayedMapData.outlinesEnabled) {
                    this.map.addLayer({
                        'id': `${area.id  }_outline`,
                        'type': 'line',
                        'source': area.id,
                        'layout': {},
                        'paint': {
                            'line-color': '#000',
                            'line-width': 1,
                            'line-opacity': (area.color.length > 0) ? 1 : 0
                        }
                    }, 'clusters')
                }
                
                this.displayedMapData.areas.push(area.id)
            },
            removeDisplayedMapData() {
                this.displayedMapData.areas.forEach(layer => {
                    this.map.removeLayer(`${layer  }_fill`)
                    if (this.displayedMapData.outlinesEnabled) {
                        this.map.removeLayer(`${layer  }_outline`)
                    }
                    this.map.removeSource(layer)
                })

                this.displayedMapData.areas = []
                this.displayedMapData.outlets = []
            },
            getAreasMapDisplayData() {

                if (!this.displayConfig.areas.display) return []

                switch (this.displayConfig.areas.type) {
                case 0:
                    return this.areas.map(area => { return {id: area.id, polygon: area.polygon, color: area.color} })
                case 1:
                    return this.areas.map(area => { return {id: area.id, polygon: area.polygon, color: (this.territories_map.get(area.territory_id) || {color: ''}).color} })
                case 2:
                    return this.areas.map(area => { return {id: area.id, polygon: area.polygon, color: (this.districts_map.get(area.district_id) || {color: ''}).color} })
                }

                return []
            },
            getOutletsMapDisplayData() {

                if (!this.displayConfig.outlets.display) return []

                const statusMap = new Map([
                    [0, {name: 'Waiting approval', color: '#3498db'}],
                    [1, {name: 'Active', color: '#2ecc71'}],
                    [2, {name: 'Deactivated', color: '#e74c3c'}]
                ])

                const thisIns = this
                const unknownObject = {name: 'Unknown', color: ''}

                switch (this.displayConfig.outlets.color) {
                case 0:
                    return this.outlets.map(outlet => {
                        return {
                            id: outlet.id,
                            geo_location: outlet.geo_location,
                            color: '#3498db',
                            popup_data: `<b>${outlet.name}</b>`
                        }
                    })
                case 1:
                    return this.outlets.map(outlet => {
                        
                        const statusObj = (statusMap.get(outlet.status) || unknownObject)
                        return {
                            id: outlet.id,
                            geo_location: outlet.geo_location,
                            color: statusObj.color,
                            popup_data: `<p><b>${outlet.name}</b></p><p>${statusObj.name}</p>`
                        }
                    })
                case 2:
                    return this.outlets.map(outlet => {
                        return {
                            id: outlet.id,
                            geo_location: outlet.geo_location,
                            color: (outlet.area_id !== thisIns.nullObjectId) ? '#2ecc71' : '#e74c3c',
                            popup_data: `<p><b>${outlet.name}</b></p><p>${(thisIns.areas_map.get(outlet.area_id) || unknownObject).name}</p>`
                        }
                    })
                case 3:
                    return this.outlets.map(outlet => {

                        const statusObj = (thisIns.outlet_segments_map.get(outlet.segment_id) || unknownObject)
                        return {
                            id: outlet.id,
                            geo_location: outlet.geo_location,
                            color: statusObj.color,
                            popup_data: `<p><b>${outlet.name}</b></p><p>${statusObj.name}</p>`
                        }
                    })
                case 4:
                    return this.outlets.map(outlet => {

                        const statusObj = (thisIns.areas_map.get(outlet.area_id) || unknownObject)
                        return {
                            id: outlet.id,
                            geo_location: outlet.geo_location,
                            color: statusObj.color,
                            popup_data: `<p><b>${outlet.name}</b></p><p>${statusObj.name}</p>`
                        }
                    })
                case 5:
                    return this.outlets.map(outlet => {

                        const statusObj = (thisIns.territories_map.get(outlet.territory_id) || unknownObject)
                        return {
                            id: outlet.id,
                            geo_location: outlet.geo_location,
                            color: statusObj.color,
                            popup_data: `<p><b>${outlet.name}</b></p><p>${statusObj.name}</p>`
                        }
                    })
                case 6:
                    return this.outlets.map(outlet => {

                        const statusObj = (thisIns.districts_map.get(outlet.district_id) || unknownObject)
                        return {
                            geo_location: outlet.geo_location,
                            color: statusObj.color,
                            popup_data: `<p><b>${outlet.name}</b></p><p>${statusObj.name}</p>`
                        }
                    })
                case 7:
                    return this.outlets.map(outlet => {
                        return {
                            geo_location: outlet.geo_location,
                            color: (outlet.area_id !== thisIns.nullObjectId) ? '#2ecc71' : '#e74c3c',
                            popup_data: `<p><b>${outlet.name}</b></p><p>${(thisIns.areas_map.get(outlet.area_id) || unknownObject).name}</p>`
                        }
                    })
                }

                return []
            },
            attachMap() {
                const thisIns = this
                return new Promise(function(resolve) {
                    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                    thisIns.map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: thisIns.$store.state.app.default_map_view.center,
                        zoom: thisIns.$store.state.app.default_map_view.zoom
                    })

                    thisIns.map.on('style.load', function() {
                        resolve()
                    })
                })
            }
        },
        watch: {
            displayConfig: {
                handler() {
                    this.loadMapData()
                },
                deep: true
            },
            clusterOutlets(val) {
                if (val) {
                    this.map.setLayoutProperty('outlet-point-unclustered', 'visibility', 'none')

                    this.map.setLayoutProperty('outlet-point', 'visibility', 'visible')
                    this.map.setLayoutProperty('cluster-count', 'visibility', 'visible')
                    this.map.setLayoutProperty('clusters', 'visibility', 'visible')
                } else {
                    this.map.setLayoutProperty('outlet-point-unclustered', 'visibility', 'visible')

                    this.map.setLayoutProperty('outlet-point', 'visibility', 'none')
                    this.map.setLayoutProperty('cluster-count', 'visibility', 'none')
                    this.map.setLayoutProperty('clusters', 'visibility', 'none')
                }
            }
        },
        computed: {
            areaLegendItems() {
                switch (this.displayConfig.areas.type) {
                case 0:
                    return this.areas.map(area => { return {name: area.name, color: area.color} })
                case 1:
                    return this.territories.map(territory => { return {name: territory.name, color: territory.color} })
                case 2:
                    return this.districts.map(district => { return {name: district.name, color: district.color} })
                }

                return []
            },
            outletLegendItems() {
                switch (this.displayConfig.outlets.color) {
                case 0:
                    return [{name: 'Outlet', color: '#3498db'}]
                case 1:
                    return [
                        {name: 'Waiting approval', color: '#3498db'},
                        {name: 'Active', color: '#2ecc71'},
                        {name: 'Deactivated', color: '#e74c3c'}
                    ]
                case 2:
                    return [
                        {name: 'Area defined', color: '#2ecc71'},
                        {name: 'Area not defined', color: '#e74c3c'}
                    ]
                case 3:
                    return this.outlet_segments.map(segment => { return  {name: segment.name, color: segment.color} })
                case 4:
                    return this.areas.map(area => { return {name: area.name, color: area.color} })
                case 5:
                    return this.territories.map(territory => { return {name: territory.name, color: territory.color} })
                case 6:
                    return this.districts.map(district => { return {name: district.name, color: district.color} })
                case 7:
                    return [
                        {name: 'Yes', color: '#2ecc71'},
                        {name: 'No', color: '#e74c3c'}
                    ]
                }

                return []
            }
        },
        mounted() {
            const thisIns = this
            const mapLoadPromise = this.attachMap()
            mapLoadPromise.finally(function() {
                thisIns.addOutletsSource()
                thisIns.addOutletsDisplayLayers()
                thisIns.loadData()
            })
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>